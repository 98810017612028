import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { MachineProperty, RecommendedMachine } from '../../types';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import cn from 'classnames';
import * as styles from './MachinesList.module.scss';
import { InputRadio } from '../Inputs';
import { parseToEnum } from 'ui';

const isLoggedIn = window.app.preloadState.isLoggedIn;
const translationState = (window as any).app.preloadState.translation;
const loginLink = window.app.preloadState.loginLink;

type MachinesListProps = {
    initialMachines: RecommendedMachine[];
    total: number;
    pageCallback: (page: number, field: MachinesSortField, ascending: boolean) => Promise<RecommendedMachine[]>;
};

export const MachinesList = ({ initialMachines, total, pageCallback }: MachinesListProps) => {

    const [machines, setMachines] = useState(initialMachines);
    const sortingRenders = useRef(0);

    const urlParams = new URLSearchParams(location.search);

    const querySortDirection = urlParams.get('sortDirection');
    const querySortField = parseToEnum(urlParams.get('sortField'), MachinesSortField, MachinesSortField.Weight);

    const [sorting, setSorting] = useState<[MachinesSortField, boolean]>([
        querySortField === MachinesSortField.Price ? MachinesSortField.Price : (querySortField === MachinesSortField.Name ? MachinesSortField.Name : MachinesSortField.Weight),
        querySortDirection === 'desc' ? false : true
    ]);

    const numberOfDivs = machines?.length > 0 ? Math.ceil(total / machines?.length) : 1;

    const mapping = Array.from(Array(numberOfDivs - 1).keys())

    useLayoutEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        urlParams.set('sortField', sorting[0]);
        urlParams.set('sortDirection', sorting[1] ? "asc" : "desc");
        window.history.replaceState({}, "", `${location.href.split('?')[0]}?${urlParams.toString()}`);
        sortingRenders.current++;
        if (sortingRenders.current === 1) {
            return;
        }
        (async () => {
            setMachines(await pageCallback(1, sorting[0], sorting[1]));
        })();
    }, [sorting]);

    return <>
        <div className={styles.filterIconContainer}>
            <Filter sorting={sorting} onSortingChange={setSorting} />
        </div>
        <div className={styles.machinesContainer}>
            {machines?.length > 0 && 
                machines.map((machine: RecommendedMachine, index: number) => (
                    <Item machine={machine} key={machine.itemNumber + index} />
                ))
            }
        </div>
        {mapping.map((x) => {
            return <MachinesObserver key={x + "_" + sortingRenders.current} page={x + 2} pageCallback={page => pageCallback(page, sorting[0], sorting[1])} />
        })}
    </>
}

export enum MachinesSortField {
    Weight = 'Weight',
    Price = 'Price',
    Name = 'Name'
}


const Filter = ({ sorting, onSortingChange }: { sorting: [MachinesSortField, boolean], onSortingChange: (sorting: [MachinesSortField, boolean]) => void }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        document.addEventListener("click", (ev) => {
            const filterRadioContainer = document.getElementById("FilterRadioContainer")!;
            const filterRadioButton = document.getElementById("FilterRadioButton")!;
            const target = (ev.target as HTMLDivElement);
            if (filterRadioContainer && !filterRadioContainer.contains(target) && !filterRadioButton.contains(target)) {
                setOpen(false);
            }
        });
    }, []); 

    const getLabelFor = (field: MachinesSortField, ascending: boolean): string => {
        const ascDesc = ascending ? translationState['plpSorting.ascending'] : translationState['plpSorting.descending'];
        switch (field) {
            case MachinesSortField.Weight:
                return `${translationState['plpSorting.weight']} (${ascDesc})`;
            case MachinesSortField.Name:
                return ascending ? translationState['plpSorting.az'] : translationState['plpSorting.za'];
            case MachinesSortField.Price:
                return `${translationState['plpSorting.price']} (${ascDesc})`;
        }
    }

    const radioHelper = (field: MachinesSortField, ascending: boolean) => ({
        label: getLabelFor(field, ascending),
        name: "sortingMethod",
        checked: sorting[0] === field && sorting[1] === ascending,
        onChange: () => onSortingChange([field, ascending])
    });

    return <>
        <button id="FilterRadioButton" className={styles.sortByButton} onClick={() => setOpen(o => !o)}>{translationState["plpSorting.sortBy"]}: <span className="font-weight-bold">{getLabelFor(sorting[0], sorting[1])}</span><span className={cn(styles.chevron, open ? styles.chevronUp : styles.chevronDown)}></span></button>
        
        {open && <div className={styles.filterRadioContainer} id="FilterRadioContainer">
            <div className={styles.sorting}>
                <InputRadio {...radioHelper(MachinesSortField.Weight, true)} />
            </div>
            <div className={styles.sorting}>
                <InputRadio {...radioHelper(MachinesSortField.Weight, false)} />
            </div>
            <div className={styles.sorting}>
                <InputRadio {...radioHelper(MachinesSortField.Name, true)} />
            </div>
            <div className={styles.sorting}>
                <InputRadio {...radioHelper(MachinesSortField.Name, false)} />
            </div>
            <div className={styles.sorting}>
                <InputRadio {...radioHelper(MachinesSortField.Price, true)} />
            </div>
            <div className={styles.sorting}>
                <InputRadio {...radioHelper(MachinesSortField.Price, false)} />
            </div>
        </div>}
    </>
}

const MachinesObserver = ({ page, pageCallback }: { page: number, pageCallback: (page: number) => Promise<RecommendedMachine[]> }) => {
    const [items, setItems] = useState<RecommendedMachine[]>([]);
    const loaded = useRef(false);

    const observer = useMemo(() => {
        const options = {
            root: document.rootElement,
            rootMargin: "200px 0px",
            threshold: 0,
        };

        const callback = async (entries: IntersectionObserverEntry[]) => {
            if (entries[0].isIntersecting && !loaded.current) {
                const data = await pageCallback(page);
                setItems(data);
                loaded.current = true;
            }
        }

        return new IntersectionObserver(callback, options);
    }, []);

    useEffect(() => {
        let target = document.querySelector(`#list_${page}`);
        observer.observe(target!);
    }, [])

    return <>
        {items.length ?
            <div className={styles.machinesContainer}>
                {items.map((machine, index) => <Item
                    key={machine.itemNumber + index}
                    machine={machine}
                />)}
            </div>
            :
            <div id={`list_${page}`} className={styles.itemsPlaceholder}>
            </div>
        }
    </>
}

const Item = ({ machine }: { machine: RecommendedMachine }) => {
    return <a href={machine.url} className={styles.recommendedMachine}>
        <div className={styles.machinesImageContainer}>
            {machine.showVolvoIcon && <img className={styles.volvoIcon} src="../assets/logos/volvoIcon.svg" />}
            {machine.imageUrl && <img className={styles.machineImage} src={machine.imageUrl} />}
        </div>

        <div className={styles.machineTextClontainer}>
            <div className={styles.machineDisplayNames}>
                <p className={styles.machineModelName}>{machine.category}</p>
                <p className={styles.machineModelCode}>{machine.displayName}</p>
            </div>

            <div className={styles.machineProperties}>
                {machine.machineProperties.map((property: MachineProperty, index: number) => (
                    <div className={styles.machinePropertiesUnit} key={index}>
                        <div className={styles.machinePropertiesUnitTitle}>
                            <p>{property.name} ({property.unit})</p>
                            <p>{property.value}</p>
                        </div>
                        <div className={styles.machinePropertiesUnitSlider}>
                            <ProgressBar min={property.min} max={property.max} value={property.value} />
                        </div>
                    </div>))
                }
            </div>

            <div className={styles.financialInfo}>
                <div className={styles.machinePrice}>
                    {(!machine.price || (!machine.showPrice && !machine.showPriceForGuestUsers)) &&
                        <a className={cn("btn btn--outlined justify-content-center")} href={machine.requestQuotationByEmail ?? ""}>{translationState["recommendedMachinesTranslations.requestProposal"]}</a>}
                    {!isLoggedIn && machine.showPrice && !machine.showPriceForGuestUsers &&
                        <a className={cn("btn btn--outlined justify-content-center")} href={loginLink}>{translationState["recommendedMachinesTranslations.logInToSeeThePrice"]}</a>}
                    {(machine.price && machine.showPrice && (isLoggedIn || machine.showPriceForGuestUsers)) && <p>{machine.price}</p>}
                </div>
                <div className={styles.machineDeliveryInfo}>
                    {machine.deliveryTime
                        ? <p>{translationState["recommendedMachinesTranslations.deliveryTimeNote"]} <span className="font-weight-bold">{machine.deliveryTime}</span>.</p>
                        : <p>{translationState["recommendedMachinesTranslations.deliveryTimeNoteUnavailable"]}</p>
                    }
                </div>
            </div>
        </div>
    </a>
}