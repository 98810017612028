import { RecommendedMachine } from "ui/src/types";
import { MachinesList, MachinesSortField } from 'ui';

function App() {

    const data: {
        machines: RecommendedMachine[],
        totalMachines: number,
        heading: string,
        pageDescriptiong: string
    } = window.app.preloadState.machineVariationList;

    const pageGet = async (page: number, field: MachinesSortField, ascending: boolean) => {
        const response = await fetch(`/api/machinesales/products?pageNumber=${page}&pageSize=${data.machines.length}&sortField=${field}&sortDirection=${ascending ? "asc" : "desc"}`, {
            method: 'GET',
            headers: {
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            }
        });
        if (!response.ok) {
            throw new Error(await response.text());
        }
        const responseData: { machines: RecommendedMachine[] } = await response.json();
        return responseData.machines;
    };

    return <div>
        <MachinesList initialMachines={data.machines} total={data.totalMachines} pageCallback={pageGet} />
    </div>
}

export default App;
